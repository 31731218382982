import { useEffect, useState } from 'react';

let sharedSupport: boolean | undefined = undefined;

/**
 * Detect if the current browser supports Object fit.
 **/
export default function useSupportsObjectFit() {
  const [supported, setSupported] = useState<boolean | undefined>(() => {
    if (sharedSupport !== undefined) return sharedSupport;
    return undefined;
  });

  useEffect(() => {
    if (supported === undefined) {
      sharedSupport = window.document.body.style.objectFit !== undefined;
      setSupported(sharedSupport);
    }
  }, [supported]);

  return supported;
}
