import React from 'react';
import { Grid, GridCols, GridItem, GridSize, Heading } from '@charlietango/ui';
import { rem } from 'polished';

import { TextVariant } from '../../styles/typography';
import ArticleCard from '../ArticleCard/ArticleCard';
import ArticleCardViewModel from '../ArticleCard/types/ArticleCardViewModel';
import ArticleSectionViewModel from './types/ArticleSectionViewModel';

type Props = ArticleSectionViewModel & {
  gridColumns?: GridCols | GridCols[];
  itemSpan?: GridSize | GridSize[];
  className?: string;
};

function ArticleSection({
  title,
  items,
  gridColumns,
  itemSpan,
  className,
}: Props) {
  return (
    <section className={className}>
      <Heading variant={TextVariant.H3} sx={{ marginBottom: 7 }}>
        {title}
      </Heading>
      <Grid gridColumns={gridColumns} gridGap={rem(48)}>
        {items &&
          items.map((card: ArticleCardViewModel, i) => (
            <GridItem size={itemSpan} key={i}>
              <ArticleCard image={card.image} link={card.link} />
            </GridItem>
          ))}
      </Grid>
    </section>
  );
}

export default ArticleSection;
