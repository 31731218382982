import * as React from 'react';

import useSupportsObjectFit from '../../hooks/useSupportsObjectFit';
import { MotionProps, motion } from '../../utils/motion';
import ImageViewModel from '../../view-models/ImageViewModel';
import { ImageSizes, generateSizes } from './image-sources';

export type ImageProps = ImageViewModel & {
  /** `sizes` is a responsive array of different image widths.
   * You should ensure this is filled out so it matches the component that renders it as closely as possible.
   * It can take any valid CSS size, including `calc()` methods.
   * */
  sizes: ImageSizes;
  loading?: 'lazy' | 'eager';
  className?: string;
  style?: React.CSSProperties;
};

const Image = React.forwardRef(
  (
    {
      src,
      srcset,
      sizes,
      width,
      height,
      loading,
      alt,
      className,
      style,
    }: ImageProps,
    imgRef: React.RefObject<HTMLImageElement>,
  ) => {
    const supportsObjectFit = useSupportsObjectFit();

    if (supportsObjectFit === false) {
      // If Objectfit isn't supported, we fall back to div with backgroundImage
      return (
        <div
          ref={imgRef}
          className={className}
          style={style}
          css={{
            width: '100%',
            height: 'auto',
            backgroundImage: `url(${src})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
      );
    }

    return (
      <img
        ref={imgRef}
        css={{
          width: '100%',
          height: 'auto',
          objectFit: 'cover',
          visibility: 'hidden',
          '@supports (object-fit: cover)': {
            visibility: 'visible',
          },
          '&:-moz-loading': {
            visibility: 'hidden',
          },
        }}
        style={style}
        src={src}
        srcSet={srcset}
        sizes={srcset && generateSizes(sizes)}
        width={width}
        height={height}
        loading={loading}
        alt={alt}
        className={className}
      />
    );
  },
);

const MotionImageElement = motion.custom(Image);

/**
 * Version of the Image, prepared to handle Motion animations.
 * This solves an issue with objectFit fallback breaking the animations, since it would switch out the element.
 * */
export const MotionImage = (props: ImageProps & MotionProps) => {
  const supportsObjectFit = useSupportsObjectFit();
  return (
    <MotionImageElement
      // Assign a unique key, so we recreate the image if objectfit changes
      key={supportsObjectFit !== false ? 'img' : 'fallback'}
      {...props}
    />
  );
};

export default Image;
