import breakpoints from '../../styles/breakpoints';

export type ImageSizes = Array<string | null | undefined>;
const defaultSize = '100vw';

/**
 * Generate sizes for images, while respecting Breakpoints
 **/
export function generateSizes(sizes?: Array<string | null | undefined>) {
  let output: string[] = [];
  if (Array.isArray(sizes)) {
    sizes.forEach((size, index) => {
      if (index > 0) {
        if (size && breakpoints[index - 1]) {
          output.unshift(`(min-width: ${breakpoints[index - 1]}) ${size}`);
        }
      } else {
        output.push(size || defaultSize);
      }
    });
  } else {
    return defaultSize;
  }

  return output.join(', ');
}
