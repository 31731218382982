import * as React from 'react';
import { Heading } from '@charlietango/ui';
import { AspectRatio, AspectRatioItem } from '@charlietango/ui';
import { rem } from 'polished';

import Anchor from '../../components/Anchor/Anchor';
import { Colors } from '../../styles/colors';
import { TextVariant } from '../../styles/typography';
import { motion, useReducedMotion } from '../../utils/motion';
import Arrow from '../Arrow/Arrow';
import { MotionImage } from '../Image/Image';
import ArticleCardViewModel from './types/ArticleCardViewModel';

function ArticleCard({ image, link = {} }: ArticleCardViewModel) {
  const shouldReduceMotion = useReducedMotion();
  return (
    <motion.article
      whileHover="hover"
      sx={{
        color: Colors.Black,
        height: '100%',
        width: '100%',
        position: 'relative',
      }}
    >
      <Anchor
        prefetch
        href={link.href}
        target={link.target}
        title={link.title !== link.label ? link.title : undefined}
        sx={{
          color: Colors.Black,
          textDecoration: 'none',
          display: 'block',
        }}
      >
        {image && (
          <AspectRatio ratio={3 / 2} css={{ overflow: 'hidden' }}>
            <AspectRatioItem>
              <MotionImage
                {...image}
                initial={{ transform: 'scale(1)' }}
                variants={
                  !shouldReduceMotion
                    ? {
                        hover: {
                          transform: 'scale(1.1)',
                        },
                      }
                    : undefined
                }
                transition={{ duration: 0.8, ease: 'easeOut' }}
                loading="lazy"
                sizes={['calc(100vw - 1.25rem)', '281px']}
                css={{
                  //transition: 'transform .8s ease',
                  backfaceVisibility: 'hidden',
                  objectFit: 'cover',
                  width: '100%',
                  height: '100%',
                }}
              />
            </AspectRatioItem>
          </AspectRatio>
        )}
        <div
          sx={{
            backgroundColor: Colors.PureWhite,
            boxShadow: '0 5px 18px 0 rgba(0, 0, 0, 0.1)',
            position: 'relative',
            marginTop: rem(-32),
            width: '90%',
            minHeight: '96px',
            ml: '-1px',
          }}
        >
          <div
            css={{
              padding: '15px 28px 15px 20px',
              height: '100%',
              width: '100%',
            }}
          >
            <Heading
              variant={TextVariant.H4}
              as="h3"
              css={{
                width: '100%',
                height: '100%',
              }}
            >
              {link.label || ''}
            </Heading>
            <Arrow
              css={{
                position: 'absolute',
                right: '18px',
                bottom: '17px',
              }}
            />
          </div>
        </div>
      </Anchor>
    </motion.article>
  );
}

export default ArticleCard;
